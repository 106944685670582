export const environment = {
  production: true,
  apiBaseUrl: 'https://hazlo-api-admin.azurewebsites.net/api', //ADMIN API
  blobUrl: 'https://sthazloprdimages01.blob.core.windows.net/images', // AZURE BLOB FOLDER
  apiPatientBaseUrl: 'https://hazlo-api-patient.azurewebsites.net/api', // PATIENT API
  patientBaseUrl: 'https://app.hazlohealth.com', // PATIENT FRONTENT
  tinyMceKey: '06dorxmfne69cp6fz27z63gn03peybnd9t0449p9kjyleb3r',
  sasKey:
    'sp=racwdli&st=2023-08-21T23:22:20Z&se=2024-08-22T07:22:20Z&spr=https&sv=2022-11-02&sr=c&sig=YVqBY43ydlqghRiq3BpwAKXijgs0AvG96eX4qRRk9MM%3D', // AZURE SAS KEY
};
